import { createStore } from 'vuex'
import * as api from "@/controllers/api";

export default createStore({
  state: {
    activeTab: '',
    services: [],
    results: {}
  },
  mutations: {
    setServices: (state, services) => {
      state.services = services
    },
    setActiveTab: (state, tab) => {
      state.activeTab = tab
    },
    clearResults: (state) => {
      state.services.forEach(service => {
        state.results[service] = []
      })
    },
    setResults: (state, data) => {
      state.results[data.service] = data.results
    }
  },
  actions: {
    initServices: (context) => {
      api.getServices((services) => {
        context.commit('setServices', services)
        context.commit('setActiveTab', services[0])
        context.commit('clearResults')
      })
    },
    search: (context, sq) => {
      context.commit('clearResults')
      for (const service of context.state.services) {
        api.getSearchResults(service, sq, (searchResults) => {
          if(!searchResults) searchResults = []
          context.commit({
            "type": 'setResults', 
            "results": searchResults, 
            "service": service
          })
        })
      }
    }
  },
  getters: {
    getResultcount: (state) => (service) => {
      if (state.results[service]){
        return state.results[service].length
      }
      return 0
    },
    getResultsByService: (state) => (service) => {
      return state.results[service]
    }
  },
  modules: {
  }
})
