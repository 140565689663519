
const baseUrl = 'https://zoeken.ondernijmegen.nl/api/'

export function getServices(callback) {
    let url = baseUrl + 'services'
    fetch(url)
        .then(response => response.json())
        .then(body => {
            callback(body.data)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}


export function getSearchResults(service, sq, callback) {
    let url = baseUrl + 'search/' + service + '/' + encodeURI(sq)
    fetch(url)
        .then(response => response.json())
        .then(body => {
            callback(body.data ?? [])
        })
        .catch((error) => {
            console.error('Error:', error);
        })
}
