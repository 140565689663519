<template>
  <div id="footer">
    <h3>Footer</h3>
  </div>
</template>

<script>
export default {
  name: "Footer",
  setup() {},
};
</script>

<style>
#footer {
  background: #444;
  color: #fff;
  width: 100vw;
  min-height: 50px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
