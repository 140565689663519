<template>
  <div id="search-bar">
    <h2>Vul hier je zoektem in:</h2>
    <div class="search-container">
      <input type="text" name="sq" v-model="sq" />
      <button @click="search">Zoek</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex"

export default {
  name: "SearchBar",
  setup() {
    const store = useStore();
    const sq = new ref("");

    const search = () => {
      if (sq.value.length > 2) {
        store.dispatch('search' , sq.value)
      } else {
        alert("Zoekterm moet minimaal uit 3 tekens bestaan");
      }
    };

    return {
      sq,
      search,
    };
  },
};
</script>

<style>
#search-bar {
  width: 100%;
  padding: 20px;
  background: #ddd;
}

#search-bar h2 {
  text-align: center;
}

#search-bar .search-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

#search-bar .search-container input[name="sq"] {
  padding: 10px 15px;
  outline: none;
  border: 2px solid #fff;
  font-size: 1.3em;
  width: 500px;
  border-radius: 5px;
}

#search-bar .search-container input[name="sq"]:focus {
  border-color: #444;
}

#search-bar .search-container button {
  padding: 10px 15px;
  font-size: 1.3em;
  width: 200px;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

#search-bar .search-container button:hover {
  background: #444;
  color: #fff;
}
</style>
