<template>
  <div id="search-tabs">
    <div
      class="tab"
      v-for="tab in tabs"
      :key="tab"
      :class="{ active: activeTab == tab }"
      @click="setActiveTab(tab)"
    >
      <span class="tab-name">{{ tab }}</span>
      <span class="count">{{ getResultcount(tab) }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "SearchTabs",
  setup() {
    const store = useStore();

    return {
      tabs: computed(() => store.state.services),
      activeTab: computed(() => store.state.activeTab),
      setActiveTab: (tab) => store.commit("setActiveTab", tab),
      getResultcount: (service) => computed(() => store.getters.getResultcount(service))
    };
  },
};
</script>

<style>
#search-tabs {
  padding: 0 20px;
  display: flex;
}
#search-tabs .tab {
  padding: 5px;
  margin-right: 10px;
  border: 2px solid grey;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
  top: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#search-tabs .tab.active {
  border-bottom-color: #ccc;
}
#search-tabs .tab .tab-name {
  text-transform: capitalize;
  font-size: 1.2em;
  margin-right: 5px;
}
#search-tabs .tab .count {
  background: #444;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
</style>
