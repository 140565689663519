<template>
  <div class="page-content">
    <!-- header -->
    <Header />
    <!-- content -->
    <router-view />
    <!-- footer -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5em;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  color: #000;
}

.page-content {
  min-height: 100vh;
  padding: 100px 0 50px;
  display: flex;
  justify-content: center;
}

.container {
  width: 1200px;
  max-width: 100vw;
  background: #ccc;
}
</style>
