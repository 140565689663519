<template>
  <div id="header">
    <h3>Header</h3>
  </div>
</template>

<script>
export default {
  name: "Header",
  setup() {},
};
</script>

<style>
#header {
  background: #444;
  color: #fff;
  width: 100vw;
  min-height: 100px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
