<template>
  <div id="search-results">
    <!-- tabs -->
    <search-tabs />
    <div class="result-container">
      <!-- Hier komen de zoekresultaten van {{ activeTab }} in te staan -->
      <ul class="results" v-if="(getResultcount(activeTab) > 0)">
        <li v-for="result in getSearchResults(activeTab)" :key="result.id">
          <a :href="result.link" target="_blank" class="result" :title="result.title">
            <span class="title">
              {{ result.title }}
            </span>
            <span class="link">
              {{ result.link }}
            </span>
            <span class="description">
              {{ result.description }}
            </span>
          </a>
        </li>
      </ul>
      <span class="no-results" v-else>
        Geen resultaten gevonden in: {{ activeTab }} 
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import SearchTabs from "@/components/SearchTabs.vue";

export default {
  name: "SearchResults",
  components: {
    SearchTabs,
  },
  setup() {
    const store = useStore();

    return {
      activeTab: computed(() => store.state.activeTab),
      getSearchResults: (service) => store.getters.getResultsByService(service),
      getResultcount: (service) => store.getters.getResultcount(service)
    };
  },
};
</script>

<style>
#search-results {
  padding: 20px;
}
#search-results .result-container {
  padding: 20px;
  border: 2px solid gray;
  min-height: 500px;
  border-radius: 20px;
}

#search-results .result-container .results {
  list-style: none;
  text-align: left;
}

#search-results .result-container .results .result {
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 5px;

}

#search-results .result-container .results .result:hover {
  background: #fff;
}

#search-results .result-container .results .result .title{
  font-weight: bold;
}

#search-results .result-container .results .result .link{
  font-size: .8em;
  color: blue;
  text-decoration: underline;
  margin-bottom: 5px;
  line-height: 1.5em;
}
</style>
