<template>
  <div class="container home">
    <!-- searchbar -->
    <search-bar />
    <!-- searchresults -->
    <search-results />
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import SearchResults from "@/components/SearchResults.vue";

export default {
  name: "Home",
  components: {
    SearchBar,
    SearchResults,
  },
};
</script>
